import $ from 'jquery';
import 'what-input';
import slickCarousel     from 'slick-carousel';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

// add sticky header

var stickyMainNav = new Foundation.Sticky($('.js-header__container'), {
  /**
   * Customizable container template. Add your own classes for styling and sizing.
   * @option
   * @type {string}
   * @default '&lt;div data-sticky-container&gt;&lt;/div&gt;'
   */
  container: '<header id="header" class="main__header"></header>',
  /**
   * Location in the view the element sticks to. Can be `'top'` or `'bottom'`.
   * @option
   * @type {string}
   * @default 'top'
   */
  stickTo: 'top',
  /**
   * If anchored to a single element, the id of that element.
   * @option
   * @type {string}
   * @default ''
   */
  anchor: '',
  /**
   * If using more than one element as anchor points, the id of the top anchor.
   * @option
   * @type {string}
   * @default ''
   */
  topAnchor: '',
  /**
   * If using more than one element as anchor points, the id of the bottom anchor.
   * @option
   * @type {string}
   * @default ''
   */
  btmAnchor: '',
  /**
   * Margin, in `em`'s to apply to the top of the element when it becomes sticky.
   * @option
   * @type {number}
   * @default 1
   */
  marginTop: 0,
  /**
    * Margin, in `em`'s to apply to the bottom of the element when it becomes sticky.
    * @option
    * @type {number}
    * @default 1
  */
   
  marginBottom: 0,
  /**
   * Breakpoint string that is the minimum screen size an element should become sticky.
   * @option
   * @type {string}
   * @default 'medium'
   */
  stickyOn: 'large',
  /**
   * Class applied to sticky element, and removed on destruction. Foundation defaults to `sticky`.
   * @option
   * @type {string}
   * @default 'sticky'
   */
  stickyClass: 'sticky',
  /**
   * Class applied to sticky container. Foundation defaults to `sticky-container`.
   * @option
   * @type {string}
   * @default 'sticky-container'
   */
  containerClass: 'sticky-container',
  /**
   * If true (by default), keep the sticky container the same height as the element. Otherwise, the container height is set once and does not change.
   * @option
   * @type {boolean}
   * @default true
   */
  dynamicHeight: true,
  /**
   * Number of scroll events between the plugin's recalculating sticky points. Setting it to `0` will cause it to recalc every scroll event, setting it to `-1` will prevent recalc on scroll.
   * @option
   * @type {number}
   * @default -1
   */
  checkEvery: -1,
});

// fixing bug for sticky navigation on mobile
if (Foundation.MediaQuery.current == 'medium' || Foundation.MediaQuery.current == 'small') {
   $('.js-header__container').foundation('_destroy');
}


// add off-canvas menu


// var mainNav = new Foundation.OffCanvas($('.js-mainNavigation'), {
//   /**
//    * Allow the user to click outside of the menu to close it.
//    * @option
//    * @type {boolean}
//    * @default true
//    */
//   closeOnClick: true,

//   /**
//    * Adds an overlay on top of `[data-off-canvas-content]`.
//    * @option
//    * @type {boolean}
//    * @default true
//    */
//   contentOverlay: true,

//   /**
//    * Target an off-canvas content container by ID that may be placed anywhere. If null the closest content container will be taken.
//    * @option
//    * @type {?string}
//    * @default null
//    */
//   contentId: "offCanvasContent",

//   /**
//    * Define the off-canvas element is nested in an off-canvas content. This is required when using the contentId option for a nested element.
//    * @option
//    * @type {boolean}
//    * @default null
//    */
//   nested: true,

//   /**
//    * Enable/disable scrolling of the main content when an off canvas panel is open.
//    * @option
//    * @type {boolean}
//    * @default true
//    */
//   contentScroll: false,

//   /**
//    * Amount of time the open and close transition requires, including the appropriate milliseconds (`ms`) or seconds (`s`) unit (e.g. `500ms`, `.75s`) If none selected, pulls from body style.
//    * @option
//    * @type {string}
//    * @default null
//    */
//   transitionTime: null,

//   /**
//    * Type of transition for the OffCanvas menu. Options are 'push', 'detached' or 'slide'.
//    * @option
//    * @type {string}
//    * @default push
//    */
//   transition: 'slide',

//   *
//    * Force the page to scroll to top or bottom on open.
//    * @option
//    * @type {?string}
//    * @default null
   
//   forceTo: null,

//   /**
//    * Allow the OffCanvas to remain open for certain breakpoints.
//    * @option
//    * @type {boolean}
//    * @default false
//    */
//   isRevealed: false,

//   /**
//    * Breakpoint at which to reveal. JS will use a RegExp to target standard classes, if changing classnames, pass your class with the `revealClass` option.
//    * @option
//    * @type {?string}
//    * @default null
//    */
//   revealOn: null,

//   /**
//    * Breakpoint at which the off-canvas gets moved into canvas content and acts as regular page element.
//    * @option
//    * @type {?string}
//    * @default null
//    */
//   inCanvasOn: null,

//   /**
//    * Force focus to the offcanvas on open. If true, will focus the opening trigger on close.
//    * @option
//    * @type {boolean}
//    * @default true
//    */
//   autoFocus: false,

//   /**
//    * Class used to force an OffCanvas to remain open. Foundation defaults for this are `reveal-for-large` & `reveal-for-medium`.
//    * @option
//    * @type {string}
//    * @default reveal-for-
//    * @todo improve the regex testing for this.
//    */
//   revealClass: 'reveal-for-',

//   /**
//    * Triggers optional focus trapping when opening an OffCanvas. Sets tabindex of [data-off-canvas-content] to -1 for accessibility purposes.
//    * @option
//    * @type {boolean}
//    * @default false
//    */
//   trapFocus: false
// });

// $('#js-navigationTrigger').click(function (e) {
// 	$('#inCanvasExample').foundation('open', e, $('#js-navigationTrigger'));
// });

// fix mobile menu checkbox for links with ids

$(".nav__item")
  .not(".is-drilldown-submenu-parent")
  .click(function () {
    console.log("worked");
    $(".checkbox-toggle").prop("checked", false); // Disable CheckBox
  });


$('.product-img').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true
});

